import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import { convertToLocalDate } from "../../../../Utils/Common/utils";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = (editContentSlider, handleGenerateQRCode) => {
  const dataColumns = [
    {
      field: "SRFProfilesSetupId",
      title: "Setup ID",
      editable: false,
      show: false,
      width: "250px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "action",
      title: "Actions",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>
          <Button
            className={"qr-button k-m-2"}
            themeColor={"primary"}
            size={"small"}
            onClick={() => handleGenerateQRCode(props.dataItem)}
          >
            <FontAwesomeIcon icon={faQrcode} size="lg" title="Generate QR" />
          </Button>
        </td>
      ),
    },
    {
      field: "SRFProfilesSetupName",
      title: "Setup Name",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td
          className="edit-link"
          onClick={() => {
            editContentSlider(props.dataItem);
          }}
        >
          {props.dataItem.SRFProfilesSetupName}
        </td>
      ),
    },
    {
      field: "SRFProfilesDetails",
      title: "Description",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },

    {
      field: "WHID",
      title: "Warehouse Id",
      editable: false,
      show: true,
      width: "250px",
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "RFVenName",
      title: "RF Ven Name",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "MTFURL",
      title: "MTF URL",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "MTFPort",
      title: "MTF Port",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "MTFProtocol",
      title: "MTF Protocol",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "auto_devcod_seqnum",
      title: "Auto Device Code Sequence",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "created_date",
      title: "Created Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.created_date)}</td>
      ),
    },
    {
      field: "modified_date",
      title: "Modified Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.modified_date)}</td>
      ),
    },
    {
      field: "created_by",
      title: "Created By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "modified_by",
      title: "Modified By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
  ];

  return dataColumns;
};
export default DataColumns;
